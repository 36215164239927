var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ProfileForm", {
        ref: "profileForm",
        attrs: {
          formType: _vm.decideCaseStatusInfo.warningFormType,
          data: _vm.profileForm,
          status: _vm.componentStatus.profileForm,
          uploadImgFlag: _vm.uploadImgFlag
        },
        on: { handleImgUpload: _vm.handleUploadImg }
      }),
      _vm.componentStatus.tabNews
        ? _c("TabNews", {
            attrs: {
              status: _vm.componentStatus.tabNews,
              TabNewsFlag: _vm.TabNewsFlag,
              newsDataID: _vm.propsDataID
            },
            on: { handleNewsData: _vm.handleNewsData }
          })
        : _vm._e(),
      _vm.componentStatus.attachment
        ? _c("Attachment", {
            ref: "attachmentForm",
            attrs: {
              data: _vm.filesId,
              uploadFileFlag: _vm.uploadFileFlag,
              status: _vm.componentStatus.attachment
            },
            on: { formData: _vm.handleFormData }
          })
        : _vm._e(),
      _vm.componentStatus.approvalRecord
        ? _c("ApprovalRecord", {
            ref: "approvalRecordForm",
            attrs: {
              caseStatus: _vm.componentStatus.approvalRecord,
              role: _vm.decideCaseStatusInfo.userRole,
              data: _vm.propSuggestion
            }
          })
        : _vm._e(),
      _c("WarningFormBtn", {
        attrs: { status: _vm.componentStatus.warningFormBtn },
        on: { doFunction: _vm.handleSubmit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }